export function workshopFormInputs(workshop) {
    return [
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'name',
                    label: this.$t('workshops.properties.name.label'),
                    placeholder: this.$t(
                        'workshops.properties.name.placeholder'
                    ),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'date',
                    property: 'date',
                    label: this.$t('workshops.properties.date.label'),
                    placeholder: this.$t(
                        'workshops.properties.date.placeholder'
                    ),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'time',
                    property: 'start_time',
                    label: this.$t('workshops.properties.start_time.label'),
                    placeholder: this.$t(
                        'workshops.properties.start_time.placeholder'
                    ),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'time',
                    property: 'end_time',
                    label: this.$t('workshops.properties.end_time.label'),
                    placeholder: this.$t(
                        'workshops.properties.end_time.placeholder'
                    ),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'address',
                    label: this.$t('workshops.properties.address.label'),
                    placeholder: this.$t(
                        'workshops.properties.address.placeholder'
                    ),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'zip_code',
                    label: this.$t('workshops.properties.zip_code.label'),
                    placeholder: this.$t(
                        'workshops.properties.zip_code.placeholder'
                    ),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'city',
                    label: this.$t('workshops.properties.town.label'),
                    placeholder: this.$t(
                        'workshops.properties.town.placeholder'
                    ),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'textarea',
                    property: 'description',
                    label: this.$t('workshops.properties.description.label'),
                    placeholder: this.$t(
                        'workshops.properties.description.placeholder'
                    ),
                    required: true,
                },
            ],
        },
        {
            type: 'form-title',
            content: this.$t('workshops.inscriptions'),
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    label: this.$t(
                        'workshops.properties.allow_inscriptions.label'
                    ),
                    property: 'allow_inscription',
                    type: 'checkbox',
                    inputProps: {
                        hint: this.$t(
                            'workshops.properties.allow_inscriptions.hint'
                        ),
                        persistentHint: true,
                    },
                },
            ],
        },
    ]
}

export function workshopCustomCompanyInputs() {
    return [
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'custom_company_name',
                    label: this.$t(
                        'workshops.properties.custom_company_name.label'
                    ),
                    placeholder: this.$t(
                        'workshops.properties.custom_company_name.placeholder'
                    ),
                    required: false,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    label: this.$t('workshops.properties.custom_logo.label'),
                    type: 'image-picker',
                    property: 'custom_logo',
                    required: false,
                    placeholder: this.$t(
                        'workshops.properties.custom_logo.label'
                    ),
                },
            ],
        },
    ]
}

export function workshopRangeInputs(workshop, number) {
    return [
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'related-items',
                    isDraggable: false,
                    property: 'slots',
                    dragProperty: 'order',
                    addText: this.$t('workshops.slots.add'),
                    default: {
                        max_apprenants: 6,
                    },
                    formData: {
                        inputs: [
                            {
                                type: 'form-row',
                                elements: [
                                    {
                                        is: 'form-input',
                                        type: 'time',
                                        property: 'start_time',
                                        label: this.$t(
                                            'workshops.properties.range.label'
                                        ),
                                        placeholder: this.$t(
                                            'workshops.properties.start_time.placeholder'
                                        ),
                                        required: true,
                                    },
                                    {
                                        is: 'form-input',
                                        type: 'time',
                                        property: 'end_time',
                                        label: this.$t(
                                            'workshops.properties.range.end'
                                        ),
                                        placeholder: this.$t(
                                            'workshops.properties.end_time.placeholder'
                                        ),
                                        required: true,
                                        inputProps: {
                                            class: 'after-input-dot',
                                        },
                                    },
                                    {
                                        is: 'form-input',
                                        type: 'number',
                                        property: 'max_apprenants',
                                        label: this.$t(
                                            'workshops.properties.max_learners.label'
                                        ),
                                        placeholder: this.$t(
                                            'workshops.properties.max_learners.placeholder'
                                        ),
                                        required: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
        },
    ]
}

export function workshopRangeDurationInputs(workshop) {
    return [
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'number',
                    property: 'range_duration',
                    label: this.$t('workshops.properties.range_duration.label'),
                    placeholder: '30',
                    required: true,
                    inputProps: {
                        suffix: 'min',
                        min: 20,
                        max: 120,
                    },
                },
            ],
        },
    ]
}

export function workshopCompanyInputs(companies) {
    const godsInputs = this.user.hasRole(['god', 'super_god'])
        ? [
              {
                  type: 'form-row',
                  elements: [
                      {
                          is: 'form-input',
                          type: 'select',
                          property: 'company',
                          label: this.$t('workshops.properties.company.label'),
                          options: companies,
                      },
                  ],
              },
          ]
        : [{}]

    return [...godsInputs]
}

export function workshopDocumentInputs() {
    return [
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'related-items',
                    isDraggable: false,
                    property: 'documents',
                    dragProperty: 'order',
                    addText: this.$t('workshops.documents_add'),
                    default: {},
                    formData: {
                        inputs: [
                            {
                                type: 'form-row',
                                elements: [
                                    {
                                        is: 'form-input',
                                        label:
                                            this.$t(
                                                'workshops.properties.document.label'
                                            ) + '*',
                                        type: 'file-picker',
                                        property: 'document',
                                        required: true,
                                        placeholder:
                                            this.$t(
                                                'workshops.properties.document.placeholder'
                                            ) + '*',
                                        inputProps: {
                                            compact: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
        },
    ]
}
