<script>
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import { Collection } from '@libs/Collections/Collection.js'
    import { mapActions, mapState, mapStores } from 'pinia'
    import { useUserStore } from '@store/user.js'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import WifiIcon from '@assets/icons/WifiIcon.vue'
    import AffectGroupDialog from '@components/Groups/AffectGroupDialog.vue'
    import { HeadsetProvider } from '@providers/HeadsetProvider.js'
    import AffectGroupMixin from '@mixins/AffectGroupMixin.js'
    import { useGlobalDialog } from '@store/globalDialog.js'
    import { HeadsetRoutesNames } from '@routes/generators/Headset.names.js'
    import { PaginatedCollection } from '@libs/Collections/PaginatedCollection.js'

    export default {
        name: 'HeadsetsTableList',
        components: {
            AffectGroupDialog,
            WifiIcon,
            BatteryLevel,
            ConnectedStatus,
        },
        mixins: [mixins.RaAlertMixin, mixins.RaDialogsMixin, AffectGroupMixin],
        props: {
            company: {
                type: Object,
                required: true,
            },
            scopedFormer: {
                type: Object,
                default: () => {},
            },
            items: {
                type: PaginatedCollection,
                default: () => [],
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['stream', 'startFormation', 'update:filters'],
        data() {
            return {
                selected: [],
                filters: {
                    models: null,
                    status: null,
                    user_ids: [],
                    group_id: null,
                },
                groups: [],
            }
        },
        created() {
            if (this.scopedFormer) {
                this.filters.user_ids = [this.scopedFormer.id]
            }
        },
        computed: {
            ...mapState(useUserStore, ['user']),
            filtersOptions() {
                return {
                    status: [
                        {
                            title: this.$t(
                                'headsets.properties.status.values.connected'
                            ),
                            value: 'connected',
                        },
                        {
                            title: this.$t(
                                'headsets.properties.status.values.disconnected'
                            ),
                            value: 'disconnected',
                        },
                    ],
                    models: new Collection(
                        this.items.map((item) => ({
                            title: item.state.model,
                            value: item.state.model,
                        }))
                    ).unique('value'),
                }
            },
            headers() {
                return [
                    {
                        title: this.$t('headsets.properties.status.label'),
                        key: 'isConnected',
                        sortable: false,
                    },
                    {
                        title: this.$t('headsets.properties.name.label'),
                        key: 'name',
                    },
                    {
                        title: this.$t('headsets.properties.user.label'),
                        key: 'user',
                    },
                    {
                        title: this.$tc('groups._name', 1),
                        key:
                            this?.user?.admin ||
                            this.user.hasRole(['god', 'super_god'])
                                ? 'state.shared_group.name'
                                : 'state.group.name',
                    },
                    {
                        title: this.$t('headsets.properties.version.label'),
                        key: 'state.last_session.version',
                        sortableName: 'lastSession.version',
                    },
                    {
                        title: this.$t('headsets.properties.details.label'),
                        key: 'state',
                        sortable: false,
                    },
                ]
            },
            groupedActions() {
                return [
                    {
                        label: this.$t('headsets.actions.stream'),
                        icon: '$headset-stream',
                        handler: (items) => {
                            this.handleStream(items)
                        },
                    },
                    {
                        label: this.$t('headsets.actions.start_formation'),
                        icon: 'fa-play',
                        handler: (items) => {
                            this.handleStartFormation(items)
                        },
                    },
                    this.getAffectGroupGrouppedAction(
                        'mdi-format-list-bulleted'
                    ),
                ]
            },
            itemActions() {
                return [
                    {
                        label: this.$t('headsets.actions.stream'),
                        icon: '$headset-stream',
                        handler: (item) => {
                            this.handleStream([item])
                        },
                    },
                    {
                        label: this.$t('headsets.actions.start_formation'),
                        icon: 'fa-play',
                        handler: (item) => {
                            this.handleStartFormation([item])
                        },
                    },
                    this.getAffectGroupItemAction('mdi-format-list-bulleted'),
                    {
                        label: this.$t('headsets.actions.edit'),
                        icon: 'fa-edit',
                        handler: (item) => {
                            this.$router.push(
                                {
                                    name: HeadsetRoutesNames.show,
                                    params: {
                                        serialNumber: item.serialNumber,
                                    },
                                },
                                this.$route
                            )
                        },
                    },
                    {
                        label: this.$t('headsets.actions.delete._name'),
                        icon: 'fa-trash',
                        handler: (item) => {
                            this.setGlobalDialog({
                                title: this.$t('headsets.actions.delete._name'),
                                message: this.$t(
                                    'headsets.actions.delete.validation'
                                ),
                                positiveButton: {
                                    label: this.$t(
                                        'headsets.actions.delete._name'
                                    ),
                                    action: async () => {
                                        await HeadsetProvider.detach(
                                            this.company.id,
                                            item.id
                                        ).call()
                                        this.$emit(
                                            'update:filters',
                                            this.filters
                                        )
                                    },
                                },
                            })
                        },
                    },
                ]
            },
        },
        methods: {
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            handleStream(headsets) {
                this.$emit('stream', headsets, () =>
                    this.$router.push({
                        name: HeadsetRoutesNames.drive,
                        query: {
                            back: this.$route.fullPath,
                        },
                    })
                )
            },
            handleStartFormation(headsets) {
                this.$emit('startFormation', headsets, () =>
                    this.$router.push({
                        name: HeadsetRoutesNames.startSession,
                        query: {
                            back: this.$route.fullPath,
                        },
                    })
                )
            },
            async updateHeadsets(groupId) {
                await HeadsetProvider.updateMass(
                    this.company.id,
                    this.selected.map((s) => s.serialNumber),
                    {
                        group_id: groupId,
                    }
                )
                    .call()
                    .finally(() => {
                        this.selected = []
                        this.$emit('update:filters', this.filters)
                    })
            },
            handleFetch(query) {
                this.filters = {
                    ...this.items.defaultQuery,
                    ...this.filters,
                    ...query,
                }
            },
        },
        watch: {
            filters: {
                handler() {
                    this.$emit('update:filters', this.filters)
                },
                deep: true,
            },
        },
    }
</script>

<template>
    <div>
        <ra-alert
            ref="alert"
            global
        />
        <ra-aside>
            <ra-filters>
                <ra-input>
                    <v-select
                        v-model="filters.models"
                        :items="filtersOptions.models"
                        :label="$tc('headsets._name', 1)"
                        density="compact"
                        multiple
                        variant="outlined"
                    ></v-select>
                </ra-input>
                <ra-input>
                    <v-select
                        v-model="filters.status"
                        :items="filtersOptions.status"
                        :label="$t('headsets.properties.status.label')"
                        density="compact"
                        multiple
                        variant="outlined"
                    ></v-select>
                </ra-input>
                <ra-user-selector
                    v-if="!scopedFormer"
                    v-model="filters.user_ids"
                    :company_id="company.id"
                    :label="$t('headsets.properties.user.label')"
                    :multiple="true"
                    density="compact"
                />
            </ra-filters>
            <ra-groups
                v-model="filters.group_id"
                :category="2"
                :companyId="
                    !scopedFormer || scopedFormer.admin ? company.id : undefined
                "
                :userId="
                    scopedFormer && !scopedFormer.admin
                        ? scopedFormer.id
                        : undefined
                "
                @created="
                    ({ name }) => alert($t('groups.alerts.added', { name }))
                "
                @deleted="
                    ({ name }) => alert($t('groups.alerts.deleted', { name }))
                "
                @edited="
                    ({ name }) => alert($t('groups.alerts.edited', { name }))
                "
                @groups:loaded="(gprs) => (groups = gprs)"
            />
        </ra-aside>
        <ra-card
            :header="{
                bg: 'primary-gradient',
            }"
        >
            <template #title>
                {{
                    $tc('headsets._table_list', Math.max(1, items.total), {
                        count: items.total,
                    })
                }}
            </template>
            <template #right>
                <v-btn
                    density="compact"
                    icon
                    variant="plain"
                    @click="handleFetch({})"
                >
                    <ra-icon>mdi-refresh</ra-icon>
                </v-btn>
            </template>
            <ra-table
                v-model="selected"
                :groupedActions="groupedActions"
                :headers="headers"
                :itemActions="itemActions"
                :items="items"
                :items-length="items.total"
                :loading="loading"
                :no-data-text="$t('headsets._no_headset')"
                v-model:items-per-page="items.perPage"
                v-bind="$attrs"
                @update:options="handleFetch($event)"
            >
                <template v-slot:item.isConnected="{ item }">
                    <v-chip
                        v-if="item.isStreaming"
                        size="x-small"
                    >
                        {{ $t('headsets.pilotage.in_streaming') }}
                    </v-chip>
                    <connected-status
                        :isLAN="item?._isLAN"
                        :value="item.isConnected"
                    />
                </template>
                <template v-slot:item.name="{ item }">
                    <ra-typo
                        maxWidth="150px"
                        no-word-wrap
                        semi
                    >
                        {{ item.name }}
                    </ra-typo>
                    <ra-typo
                        maxWidth="150px"
                        no-word-wrap
                    >
                        {{ item.state.model }}
                    </ra-typo>
                </template>
                <template v-slot:item.user="{ item }">
                    {{ item.user.identity }}
                </template>
                <template v-slot:item.state="{ item }">
                    <ra-stack
                        v-if="item.isConnected"
                        direction="horizontal"
                    >
                        <battery-level :value="item.state?.batteryLevel" />
                        <wifi-icon
                            :signalIntensity="item.state?.network?.intensity"
                            width="20px"
                        />
                    </ra-stack>
                </template>
            </ra-table>
        </ra-card>
        <component
            :is="getAffectGroupDialog()"
            :ref="getAffectGroupDialogRef()"
            :groups="groups"
            @onGroupSelected="updateHeadsets"
        />
    </div>
</template>
